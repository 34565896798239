import Request from "@/helpers/Request";
import Notification from "@/helpers/Notification";
import { setStorage, getStorage, clearStorage } from "@/helpers/LocalStorage";

import UserService from "@/services/UserService";

export async function login(email, password) {

    const req = new Request();
    const notification = new Notification();

    const response = await req.url('auth/login').post({ email, password });

    if(! response.status) {
        notification.error('Wrong password or email!');

        return false;
    }
    const userService = new UserService();
    userService.saveStorageData({
        id: response.data.user.id,
        role: response.data.user.role_id,
        image: response.data.user.image,
    });

    setStorage({
        'token': response.data.token,
        'timeCreatedToken': Date.now(),
    });

    return true;
}

export function logout() {
    clearStorage();
}

export function register(data) {
    console.log(data);
}

export function refresh() {
    clearStorage();
    window.location.href = 'auth';
}

export function check() {
    const oneHourInMilliseconds = 36000000;

    const { token, timeCreatedToken } = getStorage(['token', 'timeCreatedToken']);

    if(token && timeCreatedToken) {
        if(Date.now() < Number(timeCreatedToken) + 10 * oneHourInMilliseconds)
            return true;
    }

    return false;
}

export function getBearer() {
    return `Bearer ${getStorage('token')}`;
}
