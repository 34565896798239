import UserController from "@/controllers/UserController";
const User = new UserController();

export const store = {
    state() {
        return {
            photo: null,
            role: null,
        }
    },
    mutations: {
        init(state, { photo, role }) {
            state.photo = photo;
            state.role = role;
        },

    },
    actions: {
        auth({ commit }) {
            commit('init', {
                photo: User.photo,
                role: User.role,
            });
        },
        update({ commit }) {
            commit('init', {
                photo: User.photo,
                role: User.role,
            });
        },
    },
    getters: {
        userRole(state) {
            return Number(state.role);
        },
        userPhoto(state) {
            return state.photo;
        },
    },
};
