export function getStorage(keys) {
    if (typeof keys === 'object') {
        let result = {};

        keys.forEach(key => result[key] = localStorage.getItem(key));

        return result;
    }

    return localStorage.getItem(keys);
}

export function setStorage(data) {
    Object.keys(data).forEach(key => {
        localStorage.setItem(key, data[key]);
    });
}

export function clearStorage() {
    localStorage.clear();
}

