<template>
    <footer class="footer"></footer>
</template>

<script>
export default {
    name: 'FooterTemplate',
}
</script>

<style lang="sass">
.footer
    height: 40px
</style>
