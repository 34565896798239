import { computed } from "vue";
import { RolesEnum } from "./Roles"
import { useStore } from "vuex";

export default () => {
    const store = useStore();
    const userRoleId = computed(() => store.getters.userRole);

    const isAdmin = computed(() => userRoleId.value === RolesEnum.Admin);
    const isManager = computed(() => userRoleId.value === RolesEnum.Manager);
    const isGuide = computed(() => userRoleId.value === RolesEnum.Guide);

    return {
        isAdmin,
        isManager,
        isGuide
    }
}
