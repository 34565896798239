import axios from 'axios';

import { getBearer, refresh } from '@/controllers/AuthController';

export default class Request {

    #url;
    #headers;
    #options = {};

    #request = (method, data = {}) => {

        return axios({
            headers: this.#headers,
            method,
            url: `api/${this.#url}`,
            ...this.#options,
            data
        })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            const jsonError = error.toJSON();

            if( jsonError.status === 401 ) refresh();

            return { status: false, errors: error.response.data.errors };
        });
    }

    headers(config) {
        this.#headers = {  Authorization: getBearer(), ...config };

        return this;
    }

    url(_url) {
        this.#url = _url;

        return this;
    }

    options (data) {
        this.#options = data;

        return this;
    }

    async get () {
        const response = await this.#request('get');

        return response;
    }

    async post (body) {
        const response = await this.#request('post', body);

        if(response === false || response.status !== "success")
            return { status: false, error: response?.errors };

        const { data } = response;

        return { status: true, data };
    }

    async patch(body) {

        const { data: response = { }, status = true, errors = { } } = await this.#request('patch', body);

        if (status) {

            return typeof response === 'object'
                ? { status, response }
                : response == true
                    ? { status } : { status: false };

        }

        return { status, errors: errors?.errors };
    }

    async delete(body = null) {
        const { data: response } = await this.#request('delete', body);

        return response == true;
    }
}
