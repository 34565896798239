import { createRouter, createWebHistory } from 'vue-router';
import { check } from '@/controllers/AuthController.js';
import { RolesEnum } from '@/helpers/Roles';
import UserController from '@/controllers/UserController';

const User = new UserController();
const Booking = () => import('../views/Booking.vue');
const Bikes = () => import('../views/Bikes.vue');
const ValuesManagment = () => import('../views/ValuesManagment.vue');
const Corps = () => import('../views/Corps.vue');
const Members = () => import('../views/Members.vue');
const Profile = () => import('../views/Profile.vue');
const Auth = () => import('../views/Auth.vue');
const Calendar = () => import('../views/Calendar.vue');
const ReportHours = () => import('../views/ReportHours.vue');
const NotFound = () => import('../views/NotFound.vue');

const routes = [
    {
        path: '/',
        alias: '/booking',
        name: 'Booking',
        component: Booking,
        meta: { authorize: [RolesEnum.Admin, RolesEnum.Manager] },
    },
    {
        path: '/bikes',
        name: 'Bikes',
        component: Bikes,
        meta: { authorize: [RolesEnum.Admin, RolesEnum.Manager] },
    },
    {
        path: '/values-managment',
        name: 'ValuesManagment',
        component: ValuesManagment,
        meta: { authorize: [RolesEnum.Admin, RolesEnum.Manager] },
    },
    {
        path: '/corps',
        name: 'Corps',
        component: Corps,
        meta: { authorize: [RolesEnum.Admin, RolesEnum.Manager] },
    },
    {
        path: '/members',
        name: 'Members',
        component: Members,
        meta: { authorize: [] },
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: { authorize: [] },
    },
    {
        path: '/login',
        name: 'Auth',
        component: Auth,
        alias: ["/logout", "/register"],
        meta: { authorize: [] },
    },
    {
        path: '/calendar',
        name: 'Calendar',
        component: Calendar,
        meta: { authorize: [] },
    },
    {
        path: '/report-hours',
        name: 'Report hours',
        component: ReportHours,
        meta: { authorize: [] },
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: NotFound,
        meta: { authorize: [] },
    },
];



const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach(async (to) => {

    if(!check() && to.fullPath !== '/login') {
        return { name: 'Auth' }
    }

    if(check()) {

        if(to.fullPath === '/' && RolesEnum.Guide === Number(User.role)) return { name: 'Members' };

        if(to.fullPath === '/login') return { name: 'Booking' };

        const { authorize } = to.meta;

        if(
            to.name !== '404' &&
            authorize.length !== 0 &&
            !to.meta.authorize.includes(Number (User.role))
        ) {
            return { name: '404' };
        }

    }
});

export default router;
