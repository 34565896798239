import { useToast } from 'vue-toastification'

export default class Notification {

    constructor() {
        this._toast = useToast();

        this.options = {};
    }

    success(text) {
        this._toast.success(text, this.options);

        this.resetOptions();
    }

    error(text, error = null) {
        if(error) {

            Object.keys(error).forEach(key => {
                text = `${text}\n${error[key]}`;
            });

        }
        this._toast.error(text, this.options);

        this.resetOptions();
    }

    setTime(time) {
        this.options = { timeout: time || false };

        return this;
    }


    resetOptions() {
        this.options = {};
    }
}
