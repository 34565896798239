<template>
  <header class="header">
    <div class="flex flex-between container">
      <div class="header-logo__wrapper">
        <router-link to="/booking">
          <img :src="require('@/assets/logo.png')" alt="" />
        </router-link>
      </div>

      <template v-if="isShowMenu">
        <nav v-if="!isGuide" class="header-nav">
          <router-link class="header-nav_link" to="/bikes">Bikes</router-link>
          <router-link class="header-nav_link" to="/booking"
            >Segways</router-link
          >
          <router-link class="header-nav_link" to="/corps">Corps</router-link>
          <router-link class="header-nav_link" to="/values-managment"
            >Values</router-link
          >
          <router-link class="header-nav_link" to="/calendar"
            >Calendar</router-link
          >

          <div class="flex flex-center fade-card">
            <span class="header-nav_link">Team</span>

            <div class="header-nav__submenu_links fade-card__content">
              <router-link
                class="header-nav_link header-nav__submenu_link"
                to="/members"
                >Team</router-link
              >
              <router-link
                class="header-nav_link header-nav__submenu_link"
                to="/report-hours"
                >Report Hours</router-link
              >
            </div>
          </div>

          <div class="flex flex-center header-nav__submenu fade-card">
            <div class="avatar header-nav__submenu_avatar">
              <img class="avatar__image" :src="userPhoto" alt="" />
            </div>

            <div class="header-nav__submenu_links fade-card__content">
              <router-link
                class="header-nav_link header-nav__submenu_link"
                to="/profile"
                >My Profile</router-link
              >
              <router-link
                class="header-nav_link header-nav__submenu_link"
                to="/logout"
                >Log Out</router-link
              >
            </div>
          </div>
        </nav>

        <nav v-else class="header-nav">
          <router-link class="header-nav_link" to="/profile"
            >My Profile</router-link
          >
          <router-link class="header-nav_link" to="/report-hours"
            >Report Hours</router-link
          >
          <router-link class="header-nav_link" to="/members">Team</router-link>
          <router-link class="header-nav_link" to="/calendar"
            >Calendar</router-link
          >
          <router-link class="header-nav_link" to="/logout"
            >Log Out</router-link
          >
        </nav>
      </template>
    </div>
  </header>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import useUserRole from "@/helpers/userRole";

const store = useStore();
const route = useRoute();

const { isGuide } = useUserRole();

const userPhoto = computed(() => store.getters["userPhoto"]);

const isShowMenu = computed(() => {
  return !(route.name === undefined || route.name === "Auth");
});
</script>

<style lang="sass">
.header
  height: 80px
  border-bottom: 1px solid #d4d4d4
  margin-bottom: 20px

    //fix scroll
  margin-left: -20px
  padding-left: 20px
  & > .container
    height: 100%
  &-nav
    display: flex
    height: 100%
    &_link
      line-height: 79px
      text-decoration: none
      text-transform: uppercase
      font-size: 13px
      padding: 0px 15px
      color: #777
      transition: background-color .2s linear

    & .router-link-exact-active
      color: #555555
      background-color: rgba(252,252,252,0.8)
      border-right: 1px solid #d4d4d4
      border-left: 1px solid #d4d4d4
    &__submenu
      padding: 0 15px
      &_avatar.avatar
        width: 45px
        height: 45px
        margin-bottom: 0

        cursor: pointer
      &_links
        position: absolute
        top: 90%
        z-index: 20
        width: 150px
        overflow: hidden

        display: flex
        flex-direction: column
        background-color: #fff
        box-shadow: 0 3px 6px rgb(0 0 0 / 15%)
        border-radius: 8px
      &_link
        line-height: 30px
        padding: 10px 15px
        z-index: 5
        transition: color .3s ease-out
        &.router-link-exact-active, &:hover
          border: none
          background-color: #d4d4d43d
          &:last-child
            border-top: 1px solid #d4d4d4
            padding-bottom: 10px
      &:hover &_link:nth-child(1)
        animation: .6s fade
      &:hover &_link:nth-child(2)
        animation: .7s fade
      &:hover &_link:nth-child(3)
        animation: .8s fade

@keyframes fade
  0%
    opacity: 0
    transform: translateY(30px)
  100%
    opacity: 1
    transform: translateY(0px)
</style>
