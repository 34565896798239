<template>
    <Header />

    <router-view />

    <Footer />
</template>

<script setup>
import { useStore } from 'vuex';

import Header from '@/components/templates/Header.vue';
import Footer from '@/components/templates/Footer.vue';

const store = useStore();

store.dispatch("auth");
</script>

<style lang="sass">
@import './assets/fonts/stylesheet.sass'
@import './sass/main.sass'

html
    margin-left: calc(100vw - 100%)
    margin-right: 0
</style>
