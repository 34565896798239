import { createApp } from 'vue';
import App from './App.vue';
import router from './routes';
import { createStore } from 'vuex';
import { store } from './store/index';
import { createPinia } from 'pinia';

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const createdStore = createStore(store);
const pinia = createPinia();

const app = createApp(App);
app.config.unwrapInjectedRef = true; // for reactive provide/inject

app
    .use(router)
    .use(createdStore)
    .use(pinia)
    .use(Toast, {
        transition: "Vue-Toastification__fade",
        position: "top-right",
        timeout: 1572,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: true,
        hideProgressBar: false,
        closeButton: false,
        icon: true,
        rtl: false,

        toastClassName: 'toast-custom',
        bodyClassName: 'toast-custom__body',
        containerClassName: 'toast-custom__container',
    });

app.mount('#app');

// createApp(App).mount('#app')
